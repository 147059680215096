// import React, {useContext} from 'react'
import React from 'react'
import { Link } from 'gatsby'
// import { Link } from 'gatsby-plugin-react-intl'
import Intl from '../Intl'

// import GlobalContext from '../../context/GlobalContext'
// import AuthServices from '../../services/auth/AuthServices'
import logoImage from '../../assets/images/logoHeader.png'
import SideManuBar from '../Core/SideBarManu/SideBarManu'


const Header = () => {
    // const gContext = useContext(GlobalContext)

    // const handleLogout = (e) => {
    //     e.preventDefault()
    //     const data = { refresh: gContext.refreshToken };
    //     gContext.logout()
    //     AuthServices.logout(data).then((response) => {
    //         console.log(response)
    //         gContext.logout();
    //     }).catch((error) => {
    //         console.log(error.response.data)
    //         gContext.logout();
    //     });        
    // }
    return (
        <>
            <header className="text-tuatara-800 body-font  bg-avermass-blue-900">
                <div className="container mx-auto p-5 flex items-center justify-between">
                    <Link to="/" className="flex flex-col  title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                        <img
                            alt="AVermass" 
                            className=" block h-40px w-60 mx-12"
                            src={logoImage}
                        />
                    </Link>

                    <button className='flex md:hidden'>
                        <SideManuBar />
                    </button>
                    
                    <nav  className=" text-base justify-center text-right text-white space-x-6 hidden md:flex">
                        <Link to="/" activeClassName="border-b-2 border-gray-200" className=" hover:text-gray-900 text-avermass-black-600 text-xl font-sans"><Intl id="home"/></Link> 
                        <Link to="/app/aboutUs"activeClassName="border-b-2 border-gray-200" className=" hover:text-gray-900 font-sans text-xl"><Intl id="aboutUs"/></Link> 
                        <Link to="/app/product"activeClassName="border-b-2 border-gray-200" className=" hover:text-gray-900 font-sans text-xl"><Intl id="product"/></Link> 
                        <Link to="/app/pricing"activeClassName="border-b-2 border-gray-200" className=" hover:text-gray-900 font-sans text-xl"><Intl id="pricing"/></Link>
                        <Link to="/app/ContactUs" activeClassName="border-b-2 border-gray-200" className=" hover:text-gray-900 font-sans text-xl"><Intl id="contactUs"/></Link> 
                        <Link to="/app/blog" activeClassName="border-b-2 border-gray-200" className=" hover:text-gray-900 font-sans text-xl">Blog</Link> 
                        
                        {/* { !gContext.isLoggedIn && (
                            <Link to="/app/login" activeClassName="border-b-2 border-gary-500"
                            className=" hover:text-gray-900  text-xl font-mono"><Intl id="login"/></Link>
                        )}
                        { gContext.isLoggedIn && (
                            <Link to="/app/dashboard" className=" hover:text-gray-900  text-xl font-mono border-b-2">Dashboard</Link>
                        )}                         */}

                    </nav>
                </div>
            </header>
        </>
    )
}

export default Header

import React,{useState} from 'react';
import { Link } from 'gatsby'

function SideBarManu() {
    const[showSidebar , setShowSidebar] = useState(true);

    return ( 
        <>
        {showSidebar ? (
            <svg
            onClick={() => setShowSidebar(!showSidebar)}
            className="z-86 flex items-center cursor-pointer right-10 top-28"
            fill="#2563EB"
            viewBox="0 0 100 80"
            width="40"
            height="40"
        >
            <rect width="100" height="10"></rect>
            <rect y="30" width="100" height="10"></rect>
            <rect y="60" width="100" height="10"></rect>

        </svg>
        ):(
            <>
                
                <button
            className='z-86 flex items-center cursor-pointer right-10 top-28 text-3xl'
            onClick={() => setShowSidebar(!showSidebar)} >
                X
            </button>
                <nav className={`top-48 right-0 w-full bg-avermass-blue-900 p-10 pl-20 text-white fixed h-80 z-40  ease-in-out duration-300 ${
                    showSidebar ? "translate-x-0 " : "translate-x-full"
                }`}>
            
                        <div className="">
                            <Link to="/" activeClassName="border-b-2 border-gray-200" className=" hover:text-gray-900 text-avermass-black-600 text-2xl font-mono">Home</Link> 
                        </div>

                        <div className="m-6">
                            <Link to="/app/aboutUs"activeClassName="border-b-2 border-gray-200" className=" hover:text-gray-900 font-mono text-2xl">About</Link> 
                        </div>

                        <div className="m-6">
                            <Link to="/app/service"activeClassName="border-b-2 border-gray-200" className=" hover:text-gray-900 font-mono text-2xl">Service</Link>
                        </div>

                        <div className="m-6">
                            <Link to="/app/pricing"activeClassName="border-b-2 border-gray-200" className=" hover:text-gray-900 font-mono text-2xl">Pricing</Link>
                        </div>

                        <div className="m-6">
                            <Link to="/app/ContactUs" activeClassName="border-b-2 border-gray-200" className=" hover:text-gray-900 font-mono text-2xl">Contact</Link> 
                        </div>
            </nav>

            </>
            

        )}
    </>
    )
}

export default SideBarManu
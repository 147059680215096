import React, {useState} from 'react'
import logoImage from '../../assets/images/logoFooter.png'
import { Link } from 'gatsby'
import Intl from '../Intl'
import Modal from '../Core/Modal/Modal'
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions'
const Footer = () => {
        // Modal status
        const [isOpen, SetIsOpen] = useState(false)

        // Close Modal function
        const closeModal = () => SetIsOpen(false)

    return (
        <>
            <footer className="text-white body-font">
                <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col border-t-2 border-b-2 border-gray-600">
                    <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                        <Link className="flex title-font font-medium h-20 w-30 items-center md:justify-center justify-center text-gray-900 text-center">
                            <img
                                alt="AVermass"
                                className="block h-40 mt-5 mx-12"
                                src={logoImage}
                            />
                        </Link>
                        {/* <p className="mt-20 text-sm text-gray-300 text-center">Air plant banjo lyft occupy retro adaptogen indego</p> */}
                    </div>
                    <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-gray-300 tracking-widest text-sm mb-3"><Intl id="users"></Intl></h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <Link to='/app/login' className="text-gray-600 hover:text-gray-200"><Intl id="login"></Intl></Link>
                                </li>
                                {/* <li>
                                    <Link to='' className="text-gray-600 hover:text-gray-200"><Intl id="articals"></Intl></Link>
                                </li> */}
                                <li>
                                    <Link to='/app/signup' className="text-gray-600 hover:text-gray-200"><Intl id="signin"></Intl></Link>
                                </li>


                                {/* <li>
                                    <Link className="text-gray-600 hover:text-gray-200">Change password</Link>
                                </li> */}


                            </nav>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-gray-300 tracking-widest text-sm mb-3"><Intl id="company"></Intl></h2>
                            <nav className="list-none mb-10">
                                {/* <li>
                                    <Link className="text-gray-600 hover:text-gray-200"><Intl id="opportunities"></Intl></Link>
                                </li> */}
                                <li>
                                    <Link to='/app/ContactUs' className="text-gray-600 hover:text-gray-200"><Intl id="help"></Intl></Link>
                                </li>
                                {/* <li>
                                    <Link to='/app/aboutUs' className="text-gray-600 hover:text-gray-200"><Intl id="about"></Intl></Link>
                                </li> */}

                                <li>
                                    <Link to='/app/aboutUs' className="text-gray-600 hover:text-gray-200"><Intl id="ourteam"></Intl></Link>
                                </li>

                            </nav>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-gray-300 tracking-widest text-sm mb-3"><Intl id="setting"></Intl></h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <Link to='/app/forgot-password' className="text-gray-600 hover:text-gray-200"><Intl id="changepassword"></Intl></Link>
                                </li>
                                {/* <li>
                                    <Link className="text-gray-600 hover:text-gray-200"><Intl id="privacypolicy"></Intl></Link>
                                </li> */}
                                <li>
                                    <div onClick={() => { SetIsOpen(true) }} className="text-gray-600 hover:text-gray-200"><Intl id="termsandconditons"></Intl></div>

                                </li>
                                <Modal
                                    open={isOpen}
                                    close={closeModal}
                                    title={<Intl id="termsandconditons"></Intl>}
                                    fade={true}
                                    backdrop={true}
                                    fullscreen={"true"}
                                    centered={false}
                                    scrollable={true}
                                    transition={true}
                                    cancel={<Intl id="agree"></Intl>}
                                >
                                    <TermsAndConditions />
                                </Modal>

                                {/* <li>
                                    <Link className="text-gray-600 hover:text-gray-200">Fourth Link</Link>
                                </li> */}

                            </nav>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-gray-300 tracking-widest text-sm mb-3"><Intl id="Connectwithus"></Intl></h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <Link to='https://www.facebook.com/Verma-GmbH-105917602090798/?ref=pages_you_manage' className="text-gray-600 hover:text-gray-200">Facebook</Link>
                                </li>
                                <li>
                                    <Link to='https://twitter.com/GmbhVerma' className="text-gray-600 hover:text-gray-200">Twitter</Link>
                                </li>
                                <li>
                                    <Link to='https://www.instagram.com/verma_gmbh/?hl=en' className="text-gray-600 hover:text-gray-200">Instagram</Link>
                                </li>
                                <li>
                                    <Link to='https://www.linkedin.com/company/verma-gmbh-consulting-it-services/' className="text-gray-600 hover:text-gray-200">LinkedIn</Link>
                                </li>
                            </nav>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row items-center justify-center">
                        <p className="text-gray-200 text-sm text-center sm:text-left">© 2022  Forgey Detection —
                            <Link href="" rel="noopener noreferrer" className="text-gray-200 ml-1" target="_blank">AVermass</Link>
                        </p>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer

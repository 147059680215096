import React, { useContext } from 'react';

import GlobalContext from '../../context/GlobalContext';
import en from './en.json';
import de from './de.json';

const Intl = (props) => {
  const gContext = useContext(GlobalContext);
  let lang = null;
  if (gContext.lang === 'de') lang = de;
  else lang = en;

  if (props?.isArray) {
    return;
  }

  return <>{lang[props.id] || props.id}</>;
};

Intl.propTypes = {};

export default Intl;

import React from 'react'
import Intl from '../Intl'
function TermsAndConditions() {
    return (

        <div className='text-gray-900'>

                <Intl id="termsandconditionline0"></Intl> <br /> <br /> <br />

                <Intl id="termsandconditionline1"></Intl> <br /> <br /> <br />


                <Intl id="termsandconditionline2"></Intl> <br /><br /><br />

                <Intl id="termsandconditionline3"></Intl> <br />

                <Intl id="termsandconditionline4"></Intl> <br />

                <Intl id="termsandconditionline5"></Intl> <br />

                <Intl id="termsandconditionline6"></Intl> <br />

                <Intl id="termsandconditionline7"></Intl> <br />

                <Intl id="termsandconditionline8"></Intl> <br />

                <Intl id="termsandconditionline9"></Intl> <br />


                <Intl id="termsandconditionline10"></Intl> <br />

                <Intl id="termsandconditionline11"></Intl> <br /> <br /> <br />


                <Intl id="termsandconditionline12"></Intl> <br /><br /> <br />
                
                •	<Intl id="termsandconditionline13"></Intl> <br />
                •	<Intl id="termsandconditionline14"></Intl> <br />
                •	<Intl id="termsandconditionline15"></Intl> <br />
                •	<Intl id="termsandconditionline16"></Intl>  <br />
                •	<Intl id="termsandconditionline17"></Intl>  <br />
                •	<Intl id="termsandconditionline18"></Intl><br />
                •	<Intl id="termsandconditionline19"></Intl> <br />
                •	<Intl id="termsandconditionline20"></Intl> <br />
                •	<Intl id="termsandconditionline21"></Intl> <br />


        </div>
    )
}

export default TermsAndConditions